<template>
  <div>
    <vx-card title="حدث خطأ" class="mb-2" title-color="danger" v-if="Object.keys(errors_values).length">

      <div v-for="(key, index) in Object.keys(errors_values)" :key="index">

        <span v-for="(error, index) in errors_values[key]" :key="index" class="text-danger">
          {{ error }}
        </span>
      </div>

    </vx-card>
    <vx-card>
      <h1>إضافة علامة تجارية جديدة</h1>
      <div class="mb-5">&#8203;</div>
      <h4 class="mt-8">صور العلامة التجارية</h4>
      <div class="vx-row bottom-seperator">
        <div class="vx-col my-1 w-full mb-6">
          <div class="con-img-upload flex" id="images" v-if="brand.banners.length">
            <div class="img-upload" v-for="(image, index) in brand.banners" :key="index">
              <button type="button" class="btn-x-file" @click="spliceFile(index)">
                <i translate="translate" class="material-icons notranslate">clear</i>
              </button>
              <img v-if="image.blob" :src="image.blob" style="max-width: none; max-height: 20px" />
            </div>
          </div>
          <file-upload ref="upload" :multiple="true" v-model="brand.banners" class="vs-button bg-primary mt-5"
            post-action="/post.method" put-action="/put.method" @input-filter="inputFilter"> تنزيل صورة
          </file-upload>

          <span class="text-danger text-sm" v-show="media_error">{{
          media_error
          }}</span>
        </div>
      </div>
      <div class="container">
        <div class="vx-row">
          <div class="vx-col my-3 sm:w-full w-full mb-2">
            <vs-input data-vv-as="اسم العلامة التجارية" v-validate="'required'" class="w-full"
              label-placeholder="اسم العلامة التجارية" v-model="brand.name" name="name" />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
            }}</span>
          </div>
        </div>
        <div class="vx-row mt-8">
          <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
            <div class="mb-2"><label for="">شعار العلامة </label></div>
            <template>

              <!-- Image Container -->
              <div v-if="brand.logo" class="img-container w-64 mx-auto flex items-center justify-center">
                <img :src="brand.logo" alt="img" class="responsive">
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img ">
                <input type="file" class="hidden" ref="updateLogoInput" @change="updateCurrLogo" accept="image/*">
                <vs-button v-if="!brand.logo" class="mr-4" type="flat" @click="$refs.updateLogoInput.click()">تنزيل صورة
                </vs-button>
                <vs-button v-if="brand.logo" type="flat" color="#999" @click="brand.logo = null">مسح الصورة</vs-button>
              </div>
            </template>

          </div>
          <div class="vx-col sm:w-1/2 w-full pl-10 mb-2">
            <div class="mb-2"><label for="">صورة العلامة </label></div>
            <template>
              <!-- Image Container -->
              <div v-if="brand.image" class="img-container w-64 mx-auto flex items-center justify-center">
                <img :src="brand.image" alt="img" class="responsive">
              </div>
              <!-- Image upload Buttons -->
              <div class="modify-img ">
                <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                <vs-button v-if="!brand.image" class="mr-4" type="flat" @click="$refs.updateImgInput.click()">تنزيل صورة
                </vs-button>
                <vs-button v-if="brand.image" type="flat" color="#999" @click="brand.image = null">مسح الصورة
                </vs-button>
              </div>
            </template>
          </div>
        </div>

      </div>

      <!-- submitting -->
      <div class="vx-row mt-8 btn-alignment">
        <div class="vx-col sm:w-full w-full mb-6">
          <vs-button class="mr-5 text-lg py-4" @click="reset" color="warning" type="border">تفريغ الحقول</vs-button>
          <vs-button class="mr-5 text-lg px-16 py-4" @click="submit" color="success" type="filled">حفظ</vs-button>
        </div>
      </div>



    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VueUploadComponent from 'vue-upload-component'
// Store Module
import moduleBrand from "@/store/brands/moduleBrand.js";
export default {
  components: {
    vSelect,
    'file-upload': VueUploadComponent
  },
  data() {
    return {
      errors_values: {},
      media_error: '',
      brand: {
        name: "",
        image: null,
        logo: null,
        banners: []
      },
    };
  },
  methods: {
    reset() {
      this.brand = {
        name: "",
        image: null,
        logo: null,
        banners: []
      };
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.brand.image = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    updateCurrLogo(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.brand.logo = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    spliceFile(index) {
      this.brand.banners.splice(index, 1);
    },
    inputFilter(newFile, oldFile, prevent) {
      if ((newFile.size / 1024.0) > 5000) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
          color: "danger",
        });
        return prevent();
      }
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يجب أن يكون الملف بإمتداد صورة صحيح",
            color: "danger",
          });
          return prevent();
        }
      }
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var bodyFormData = new FormData();
          bodyFormData.set("name", this.brand.name);
          bodyFormData.set("image", this.brand.image);
          bodyFormData.set("logo", this.brand.logo);


          for (var i = 0; i < this.brand.banners.length; i++) {
            let file = this.brand.banners[i].file;
            bodyFormData.append("banner[" + i + "]", file);
          }

          this.$store
            .dispatch("brand/createNewBrand", bodyFormData)
            .then(() => {
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تم اضافة العلامة بنجاح",
                color: "success",
              });
            }).catch((err) => {

              this.errors_values = err.response.data.errors;
              this.$vs.notify({
                title: "حدث خطأ",
                text: err.response.data.message,
                color: "danger",
              });

              if (err.response.data.errors && err.response.data.errors.banner) {
                this.media_error = 'حقل الصورة مطلوب يرجى اضافة على الاقل صورة واحدة'
              }
            })
        }
      });
    },
  },
  created() {
    if (!moduleBrand.isRegistered) {
      this.$store.registerModule("brand", moduleBrand);
      moduleBrand.isRegistered = true;
    }
  }

};
</script>

<style lang="scss">
.btn-upload-file {
  display: none;
}

.seperator {
  border-left: 1px solid rgb(204, 204, 204);

}

.bottom-seperator {
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 4em;
}

.btn-alignment {
  text-align: right;
}
</style>
